import React from "react";
import About from "../../components/About/About";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUs";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartzService";
import videobg from "../../assets/videos/4.mp4"
import videohead from "../../assets/videos/2.mp4"
import videoblue from "../../assets/videos/4.mp4"
import videobody from "../../assets/videos/bg.mp4"
import logoImage from "../../assets/img/logo/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import useLang from '../../hooks/useLang';

const Pricing = () => {
  const {
    lang,
    ActiveEN,
    ActiveES
  } = useLang();

 
  
  return (
    <LayoutGivartz>
      <main className="fix" >
       {/* <WhoWeAre /> */}
       <section className="rene-sensa">
       <section className="video-rene">
              <div className="flex-rene-led">
                        <h1  className="Text-Big">        
                            
                            {lang.data.Pricing.Header.n01}
                        </h1>
                </div>
              <video 
                className="video-bg-rene"
                autoPlay={true}
                loop={true} 
              playsInline={true}
              muted={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true"
              
              >
                {/* <source  src={videobg} type="video/mp4"/>
                  <source  src={videobg} type="video/webm"/>
                <source src={videobg} type="video/ogg"/> */}
                <source  src={videohead} type="video/mp4"/>
                      <source  src={videohead} type="video/webm"/>
                    <source src={videohead} type="video/ogg"/>



                </video>





          </section>

       </section>

       <section  className="rene-sensa-04">

<div className="box-rene-design">


<h2 className="text-arena-rene">
{lang.data.Pricing.Header.n02}
                  </h2>


  <div>
 
              </div>

            

    

    

  </div>


</section>





     
        
      
      
       
        <WhyChooseUsTwo />
              
<div className="pico-background-ricky">
          <div className="pricing-box">

            <div className="hijo-box">

           
              
              {/* <h5></h5> */}
              <h3>{lang.data.Pricing.Section03.n01}</h3>


              <h4 className="">
              
              {lang.data.Pricing.Section03.n02}
              </h4>

              <p className="">
              {lang.data.Pricing.Section03.n03}
              <br/>
              {lang.data.Pricing.Section03.n04}
              </p>

                <div className="princingblok3"> 


                <span>
                {lang.data.Pricing.Section03.n05}
              </span>
              <a 
            target="_blank" rel="noopener"
            href="https://sublaunch.co/picoaipickspro"
            >
                <button>
                {lang.data.Pricing.Section03.n06}
                <br/>
                {lang.data.Pricing.Section03.n07}
                </button>

              </a> 


                </div>
             
              
            </div>
          </div>
           </div>
        <Faq />
        {/* <CounterArea /> */}
        {/* <RoadmapTwo /> */}
        {/* <TeamTwo /> */}
        {/* <DownloadArea /> */}
        {/* 
        <ContactTwo /> */}
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default Pricing;
